import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

function Profile() {
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`https://server.theeternal.info/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
    }, []);
    const handleClose = () => {
        setShowPopup(false);
    };
    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };
    return (
        <>
            <div className="app1">
                <div className="account">
                    <div className="account__top">
                        <div className="account__heading">Trung tâm thành viên</div>
                        <div className="account__ID">
                            ID: <span id="account__ID">{profile ? <span>{Number(profile?.iduser) + 3000}</span> : null}</span>
                            <span id="user" style={{ position: "absolute", right: "20px" }}>
                                {profile ? <span>{profile.username}</span> : null}
                            </span>
                        </div>
                        <div className="account__balance">
                            <span>Số tiền tài khoản</span>
                            {profile ? <strong id="account__balance">{Math.floor(profile?.money).toLocaleString()}</strong> : null}
                        </div>
                    </div>
                    <div className="account__transaction">
                        <div className="account__transaction-box">
                            <Link onClick={() => setShowPopup(true)} className="account__transaction-item">
                                <AddCardOutlinedIcon />
                                <span>Nạp điểm</span>
                            </Link>
                            <div className="account__transaction-line"></div>
                            <Link to="/withdraw" className="account__transaction-item">
                                <PriceChangeOutlinedIcon />
                                <span>Rút điểm</span>
                            </Link>
                        </div>
                    </div>
                    <div className="account__menu">
                        <Link to="/historyplay" className="account__menu-item">
                            <span>
                                <QueryStatsOutlinedIcon sx={{ fontSize: "25px" }} />
                                Lịch sử tham gia
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/award" className="account__menu-item">
                            <span>
                                <CardGiftcardIcon sx={{ fontSize: "25px" }} />
                                Lịch sử nhận thưởng
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        {/*<Link to="/biendongsodu" className="account__menu-item">
							<span>
								<LocalAtmOutlinedIcon sx={{ fontSize: "25px" }} />
								Biến động Số tiền
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>*/}
                        <Link to="/history" className="account__menu-item">
                            <span>
                                <CreditScoreOutlinedIcon sx={{ fontSize: "25px" }} />
                                Lịch sử nạp
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/historyget" className="account__menu-item">
                            <span>
                                <PaymentsOutlinedIcon sx={{ fontSize: "25px" }} />
                                Lịch sử rút
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/addbank" className="account__menu-item">
                            <span>
                                <AccountBalanceOutlinedIcon sx={{ fontSize: "25px" }} />
                                Thêm tài khoản ngân hàng
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/resetpassword" className="account__menu-item">
                            <span>
                                <LockPersonOutlinedIcon sx={{ fontSize: "25px" }} />
                                Đổi mật khẩu
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <div
                            className="account__menu-item"
                            onClick={() => {
                                localStorage.removeItem("user");
                                navigate("/login");
                            }}>
                            <span>
                                <LogoutOutlinedIcon sx={{ fontSize: "25px" }} />
                                Đăng xuất
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="popup" onClick={handleBackdropClick}>
                    <div className="popup-content">
                        <form className="formbet">
                            <div className="a">
                                <div className="money_bet_user">
                                    <div className="list_money_bet">
                                        <div className="title-nhapxuat">NẠP ĐIỂM</div>
                                        <p>Vui lòng liên hệ với CSKH để được hướng dẫn nạp điểm</p>
                                        <button onClick={handleClose} className="btn-cancel">
                                            Đồng ý
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Profile;
